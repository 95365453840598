import React from 'react'
import './PaymentForm.css'
import qrPayImage from './qrPay.jpeg'


function BinancePay() {
  return (
    <div className='binance'>
    <h1>Binance Pay</h1>
    <h3>Procedure</h3>
    <p>Go To Binance App </p>
    <p>Click On Your Profile on top left Corner Menu</p>
    <p>Ensure You Have Toggled To Binance Pro</p>
    <p>Click Pay Then Send Then Pay ID</p>
    <p>Pay Id: <strong>3456778</strong></p>
    <div className='qrImage'>
      <img src={qrPayImage}  alt="qrimage" />
    </div>
  </div>
  )
}

export default BinancePay

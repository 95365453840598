import React from 'react'
import PaymentForm from './Pages/PaymentForm';

function App() {
  
  return (
    <div>
      <PaymentForm/>
   </div>
  )
}

export default App

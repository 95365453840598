
import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { MdEmail, MdAttachEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaUserTie, FaInstagram, FaWallet } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5';
import {SiBinance} from "react-icons/si"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './PaymentForm.css';
import Mpesa from './Mpesa';
import BinancePay from './BinancePay';
import CryptoWallet from './CryptoWallet';
import AirtelMoney from './AirtelMoney';

const PaymentForm = () => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [createPassword, setCreatePassword] = useState('');

  const form = useRef();

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\+?\d{10,15}$/;  // accepts only 10-15 digits (can be prefixed with a + for international format)
    return regex.test(phoneNumber);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
        alert("Invalid email format");
        return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
        alert("Invalid phone number format");
        return;
    }

    if (confirmPassword !== createPassword) {
        alert("Passwords do not match!");
        return;
    }

    setSubmitStatus('LOADING');

    emailjs.sendForm('AstraWrld', 'template_1pnfhyx', form.current, 'LbpYQnBahE4-QU9jx')
      .then((result) => {
          console.log(result.text);
          setFirstName('');
          setLastName('');
          setPhoneNumber('');
          setEmail('');
          setSubmitStatus('OK');
      }, (error) => {
          console.log(error.text);
          setSubmitStatus('FAILED');
      });
  };

  useEffect(() => {
    if (submitStatus === 'OK') {
      setTimeout(() => {
        setSubmitStatus('DONE');
      }, 3000);
    }
  }, [submitStatus]);

  const handlePaymentOption = (event, method) => {
    event.preventDefault();
    setPaymentMethod(method);
  };

  const renderPaymentMethod = () => {
    switch (paymentMethod) {
      case 'mpesa': return <Mpesa />;
      case 'binance': return <BinancePay />;
      case 'crypto': return <CryptoWallet />;
      case 'airtel': return <AirtelMoney />;
      default: return null;
    }
  };

  return (
    <div className="payment-form">
      {submitStatus === 'OK' ? (
        <div>
          <div className='success'>Form submitted successfully! <IoCheckmarkDoneCircleSharp className="icon-zoom" /></div>
          

        </div>
        ) : submitStatus === 'DONE' ? (
            <div>
              <h3>Pay Via:</h3>
              <div className='paymentButtons'>
                <button className="btn-Choice" onClick={(event) => handlePaymentOption(event, 'mpesa')}>Mpesa</button>
                <button className="btn-Choice" onClick={(event) => handlePaymentOption(event, 'binance')}><SiBinance className='icon'/>BinancePay</button>
                <button className="btn-Choice" onClick={(event) => handlePaymentOption(event, 'crypto')}><FaWallet className='icon'/> CryptoWallet</button>
                <button className="btn-Choice" onClick={(event) => handlePaymentOption(event, 'airtel')}>AirtelMoney</button>
              </div>
              {renderPaymentMethod()}

              <div className='btn-submit'>
              <a href="mailto:your-email@example.com?subject=Receipt Upload" target="_blank" rel="noopener noreferrer">
                  <button><MdAttachEmail className='icon'/> Upload Receipt Via Email</button>
              </a>
              <a href="https://wa.me/254774575419?text=I'm%20uploading%20my%20receipt." target="_blank" rel="noopener noreferrer">
                  <button><IoLogoWhatsapp className='icon'/> Upload Receipt Via WhatsApp</button>
              </a>
              </div> 
          </div>
        
      
      ): 
      
      <form ref={form} onSubmit={sendEmail}>
      <h1>Payment Form</h1>
          
      <div class="form">
      
      <div class="input-container ic1">
      <input type="text" name="firstName" id="firstName" class="input" placeholder=" " value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        <div class="cut"></div>
        <label for="firstName" class="placeholder"><div className='label-content'><FaUserTie className='icon'/> First name</div></label>
      </div>
      <div class="input-container ic2">
      <input type="text" name="lastName" id="lastName" class="input" placeholder="" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        <div class="cut"></div>
        <label for="lastName" class="placeholder"><FaUserTie className='icon'/> Last name</label>
      </div>
      <div className='inputPhone'>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
        
        <div class="input-container ic2">
        
        <input type="tel" name="phoneNumber" id="phoneNumber" class="input" placeholder=""  onChange={(e) => setPhoneNumber(e.target.value)} />
          <div class="cut"></div>
          <label for="phoneNumber" class="placeholder"> Phone Number </label>
        </div>
      </div>

    


      <div class="input-container ic2">
      <input type="email" name="email" id="email" class="input" placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} required/>
      <div class="cut"></div>
        <label for="email" class="placeholder"><MdEmail className='icon'/> Email</label>
      </div>
      <div class="input-container ic2">
      <input type="text" name="instagramUsername" id="instagramUsername" class="input" placeholder=" " />
        <div class="cut"></div>
        <label for="instagramUsername" class="placeholder"><FaInstagram className='icon'/> Instagram Username</label>
      </div>
      
      <div class="input-container ic2">
        <select name="paymentMethod" id="paymentMethod" class="input" required>
            <option value="" disabled selected>Select your payment method</option>
            <option value="Mpesa">Mpesa</option>
            <option value="BinancePay">BinancePay</option>
            <option value="CryptoWallet">CryptoWallet</option>
            <option value="AirtelMoney">AirtelMoney</option>
            
        </select>

      </div>
      
      <div class="input-container ic2">
      <input type="password" name="createPassword" id="createPassword"  value={createPassword}  class="input" onChange={(e) => setCreatePassword(e.target.value)} placeholder=" " required/>
      <div class="cut"></div>
        <label for="createPassword" class="placeholder"><RiLockPasswordLine className='icon'/> Create Password</label>
      </div>

      <div class="input-container ic2">
      <input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword}  class="input" onChange={(e) => setConfirmPassword(e.target.value)}  placeholder=" "  />
      <div class="cut"></div>
        <label for="confirmPassword" class="placeholder"><RiLockPasswordLine className='icon'/> Confirm Password</label>
      </div>

              
      
    </div>


      
    <button type="submit" id="button" className="submit" value="Send Email">
        {submitStatus === 'LOADING' ? <AiOutlineLoading3Quarters className="icon-spin" /> : submitStatus === 'DONE' ? <IoCheckmarkDoneCircleSharp className="icon-zoom" /> : 'Submit'}
    </button>

             
             
    </form>

      }
      
    </div>
  );
}

export default PaymentForm;

import React from 'react'
import './PaymentForm.css'


function Mpesa() {
  return (
    <div className='mpesa'>
      <h1>Mpesa Payment</h1>
      <h3>Procedure</h3>
      <p>Go To Mpesa </p>
      <p>Pay Using Paybill</p>
      <p>Paybill Number: <strong>800200</strong></p>
      <p>Account Number: <strong>0716619886</strong></p>
      <h1>Mpesa Users in Tanzanina, Uganda, Rwanda, Zimbabwe ... </h1>
      <p>Send Money to Kenya: <strong>0716619886</strong></p>
      <p>Name: Celine</p>
    </div>
  )
}

export default Mpesa

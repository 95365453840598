import React from 'react'
import './PaymentForm.css'


function AirtelMoney() {
  return (
    <div className='airtel'>
      <h1>Airtel Money Payment</h1>
      <h3>Procedure</h3>
      <p>Go To Aitel Money App </p>
      <p>Send Money locally to Kenya for Kenyans  </p>
      <p>International money transfer to Kenya</p>
      <p>Phone Number: <strong>0784796287</strong></p>
      <p> Name: <strong>Ferdinand</strong></p>

    </div>
  )
}

export default AirtelMoney

import React from 'react'
import './PaymentForm.css'
import {FaBtc, FaEthereum} from "react-icons/fa"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CryptoWallet() {
  return (
    <div className='wallet'>
    <h1>Crypto Networks</h1>
    <h3>Copy Your Preferred Network</h3>
    <p><FaBtc className="networkIcon"/> <strong>BTC - Bitcoin:</strong> <div className='walletAddress'>14LpEezQvui7YPrsJ3nyn3cy3FVrvLNUH8</div></p>
    <p><FaEthereum className="networkIcon"/> <strong> ETH-Etherium(ERC20):</strong> <div className='walletAddress'>0xcaf6e53b2bc1459f416700ba405fae1f72d01215</div></p>
    <FontAwesomeIcon icon = "fa-brands fa-ethereum"/>
</div>

  )
}

export default CryptoWallet
